@import '../../assets/scss/_constant.scss';

.app-form-input {
  border-color: #d9d9d9 !important;
  font-size: 16px !important;
  padding: 20px 15px !important;
  &:focus {
    border: 1px solid #9a9a9a !important;
    box-shadow: none !important;
  }
}

.login-container {
  // background: linear-gradient(to right, #25aaa5 0%, #008781 100%);
  background: linear-gradient(to right,  #00839B 0%,  #00839B 100%);

  height: 100%;
  .logo {
    display: flex;
    margin: 15px 45px;
  }

  .logo > img {
    height: 50px;
  }


  .right-side {
    background: url("../../assets/images/pattern.png");
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include resp($medium) {
      width: 100%;
      height: 100vh;
      display: block;
    }

    .form-container {
      background-color: white;
      -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.13);
      -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.13);
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.13);
      padding: 30px;
      max-width: 370px;
      border-radius: 7px;
      position: relative;
      @include resp($medium) {
        margin: 85px auto;
      }
      @include resp($xsmall) {
        max-width: 300px;
      }

      .login-button {
        width: 100%;
        height:50px;
        padding: 13px;
        text-align: center;
        font-size: 16px;
        border-radius: 7px;
        cursor: pointer;
        margin-top: 15px;
        color: white;
        font-family: $primary-font;
        background: linear-gradient(to right,  #00839B 0%,  #00839B 100%);

        // background: linear-gradient(to right, #25aaa5 0%, #008781 100%);
        background: -moz-linear-gradient(left, #00839B 0%, #00839B 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, #00839B), color-stop(100%, #00839B));
        background: -webkit-linear-gradient(left, #00839B 0%, #00839B 100%);
        background: -o-linear-gradient(left, #00839B 0%, #00839B 100%);
        background: -ms-linear-gradient(left, #00839B 0%, #00839B 100%);
        background: linear-gradient(to right, #00839B 0%, #00839B 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5db1', endColorstr='#f85032', GradientType=1 );
      }
    }
  }
}

