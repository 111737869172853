$primary-font: 'open-sans-regular';
$primary-font-bold: 'open-sans-bold';
$primary-font-light: 'open-sans-light';
$primary-font-semibold: 'open-sans-semibold';

// BREAKPOINT 
$large    : 1199.98px;
$medium   : 991.98px;
$small    : 767.98px;
$xsmall   : 575.98px;


$base-image-path: './../images/';

@mixin resp($media) {
    @media(max-width:$media)
    {
      @content;
    }
}
