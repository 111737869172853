.notificationPopup{
    position: absolute;
    top: 45px;
    z-index: 1;
    width: 358px;
    max-height: 371px;
    background: #FFFFFF;
    border: 1px solid #A0DFC4;
    box-shadow: 0px 23px 24px 5px rgba(4, 102, 115, 0.3);
    border-radius: 10px;
    overflow: auto;
  }
  
  .NotifyDoc{
    right: 0;
  }
  
  .NotifyPopupScreen{
    right: 60px; 
  }
  .NOTIFY_POPUP_COUNT{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "GreycliffCFBold";
    width: 22px;
      height: 20px;
      top: 9px;
      /* left: -10px; */
      background-color: red;
      border-radius: 50%;
      color: #FFFFFF;
      font-size: 10px;
      font-weight: bolder;
  }
  .NOTIFY_POPUP_GREEN_DESIGN{
    background-color: #78e08f;
      width: 6px;
      height: auto;
      border-radius: 10px 0 0 10px;
  }
  /* .NOTIFY_POPUP_PRIMARY_TEXT{
    font-family: "VisueltMedium";
    letter-spacing: -0.3px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  color: #013C44;
  margin: 10px 20px;
  word-break: break-all;
  } */
  .NOTIFY_POPUP_PRIMARY_TEXT {
    font-family: "VisueltMedium";
    font-size: 14px;
    font-style: normal;
    text-align: justify;
    color: #013C44;
    margin: 10px 20px;
    overflow-wrap: break-word;
      hyphens: manual;
  }
  .NOTIFY_POPUP_DATE{
    display: flex;
    margin: 0 0 10px 20px;
    font-family: "VisueltMedium";
    letter-spacing: -0.3px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: #66B5C3;
  margin-right: 20px;
    float: right;
  }
  .notificationList:first-child{
    margin-top: 30px;
  }
  .notificationList{
    display: block;
    margin: 0 auto 10px auto;
    width: 309.05px;
  min-height: 48.58px;
  
  background: #F3F9FB;
  border: 1px solid #C4F0DD;
  box-sizing: border-box;
  border-radius: 10px;
  }
  .header_popup_dot{
    margin-left: 10px;
  }
  .header_popup_dot2{
    position: absolute;
    bottom: 0;
    right: 1px;
  }
  
  .header_popup_profile{
    width: 31px; 
    height: 31px; 
    border: 2px solid #C4F0DD;
    border-radius: 50%;
    margin-left: 10px;
  }
  .header_popup_drName{
    font-family: 'GreycliffCFBold';
    width: 170px;
  margin-left: 10px;
  letter-spacing: -0.3px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  color: #013C44;
  }
  .dotdr{
    position: absolute;
      width: 9px;
      height: 9px;
      background-color: red;
      top: 18px;
      left: 23px;
      border-radius: 50%;
  }
  