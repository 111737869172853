@import './_constant.scss';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 242px;
  display: block;
  z-index: 3;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  overflow-x: hidden;
  background-position: center center;
  background: linear-gradient(to right,  #00839B 0%,  #00839B 100%);

  .nav {
    list-style-type: none;
  }
  .sidebar-header {
    margin-left:0px;
    padding: 10px;
    font-size: 18px;
    background: white;
    border-bottom: 1px solid #00839B;
    font-family: $primary-font-bold;
    color: black;
    font-size: 24px;
  }
  
  .sidebar-headerimage {
    margin-left: 35px;
    padding: 10px;
    font-size: 18px;
    background: white;
    border-bottom: #00839B;
    font-family: $primary-font-bold;
    color: black;
    font-size: 24px;
  }
  

  .sidebar-header > img {
    height: 50px;
    margin-right: 25px;
  }
  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    z-index: 4;
    padding-bottom: 30px;
    overflow: auto;
  }

  .nav {
    list-style-type: none;
    padding-top: 15px;
    padding-left: 0px;
    .active {
      color: white !important;
      .list-icon {
        color: #17a9f3;
      }
      .circle-icon {
        i {
          color: #17a9f3;
        }
      }
    }
    li {
      width: 100%;
      .sidebar-icon {
        min-width: 40px;
      }
      .main-list {
        display: flex;
        align-items: center;
        font-size: 18px;
        padding: 10px 20px;
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        &.active {
          border-left: 5px solid #148681;
          padding: 10px 18px;
        }
      }
      .collapse-card {
        background-color: transparent;
        border: 0;
      }
      .card {
        .card-body {
          padding-bottom: 8px !important;
          a {
            color: #fff;
            opacity: 0.86;
            margin: 5px 0 0 0;
          }
        }
      }
      > a {
        color: #fff;
        opacity: 0.86;
        margin: 5px 0 0 0;
      }
    }
    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    .span-shortname {
      font-size: 12px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }

    .list-icon {
      float: left;
      margin-right: 15px;
      text-align: center;
    }
  }
}

.main-panel {
  background: #f9fafc;
  position: relative;
  float: right;
  width: calc(100% - 242px);
  height: 100vh;
}

.headerBack {
  background: white;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);

  .topbar-app-title {
    font-family: $primary-font-bold;
  }

  .user-proifle-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.route-height {
  padding: 10px 24px;
  overflow: auto;
}

.main-panel,
.sidebar,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  -o-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.title {
  font-family: 'open-sans-semibold';
}

.headerBack {
  .custom-select__placeholder {
    color: white !important;
  }
}
