.btn{
    background-color: blue;
}

.title{
    /* color:white; */
    font-size: large;
}
.text{
    /* color: #00839B; */
}

.HAs_Container_Wraper{
    background-color: #F3FAFC;
    margin-bottom: 20px;
    width: 50%;
}
.viewbutton{
width: 50%;
margin-bottom: 8px;
padding: 4px;
border-radius: 16px;
border: none;
color: #00839B;
background-color: rgb(217, 235, 227);
cursor: pointer;
font-size: large;

}

.dateandtime{
    color: #00839B;
    font-size: large;

}