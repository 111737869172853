@font-face {
  font-family: 'open-sans-regular';
  src: url("./../../assets/font/VisueltPro-Regular.ttf");
  font-weight: 1200;
  font-size: 16px;
  color: #013C44;
}
@font-face {
  font-family: 'open-sans-light';
  src: url("./../../assets/font/VisueltPro-Medium.ttf");
  color: #013C44;  
}
@font-face {
  font-family: 'open-sans-bold';
  src: url("./../../assets/font/VisueltPro-Medium.ttf");
  color: #013C44;
}
@font-face {
  font-family: 'open-sans-semibold';
  src: url("./../../assets/font/VisueltPro-Medium.ttf");
  color: #013C44;
}
  
  