
#root,body,.App {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}


code {
  font-family: 'Poppins', sans-serif;
}

.flor-map-cont {
  height: 100%;
  padding-bottom: 20px;
}



.leaflet-container {
  height: 100%;
}
.resetBtn{
	position: absolute;
  top: 140px;
	left: 51px;
	width: 34px;
	height: 34px;
	z-index: 10000;
	padding:0px !important;
}
.sai{
  width: 12px !important;
}
