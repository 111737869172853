.ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.ant-table-tbody {
  color: #013C44;  
  .ant-table-row:nth-child(even) {
        background-color: white;
    }
}
.ant-table-thead {
  color: #013C44;
    .ant-table-cell {
        font-size: 14px;
        font-weight: 600;
        background-color: #F0F5F5;
        color: #013C44;
        text-transform: uppercase;
    }
}
.ant-btn {
  border-radius: 4px !important;
}
.ant-input-search {
    input {
        min-width: 212px;
    }
    .ant-input-search-button {
        display: flex;
        align-items: center;
    }
}
.footerDoc{
  .ant-modal-body {
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    padding: 10px 20px 40px 20px !important;
}
}
.no-modal-footer {
 
  .ant-modal-footer {
    display: none;
  }
}
.ant-form-item-control-input-content {
  input {
    border-radius: 6px;
  }
}
.ant-select-selector {
  border-radius: 6px;
}

.ant-upload.ant-upload-select-picture-card{
      width:100%!important;
}

.ant-upload-select-picture-card > span > img{
  height: 235px;
  width: 235px!important;
}

.read-form-input {
  .read-form-value {
      border: 1px solid rgba(0,0,0,0.05);
      padding: 10px;
      margin-top: 10px;
  }
}