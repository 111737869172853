@import "./_constant.scss";

@for $i from 0 through 100 {
    .border-radius--#{$i} { border-radius: $i * 1px !important; }

    .fs--#{$i} { font-size: $i * 1px !important; }

    .pa--#{$i} { padding: $i * 1px !important; }

    .pr--#{$i} { padding-right: $i * 1px !important; }

    .pl--#{$i} { padding-left: $i * 1px !important; }

    .pt--#{$i} { padding-top: $i * 1px !important; }

    .pb--#{$i} { padding-bottom: $i * 1px !important; }

    .plr--#{$i} {
      padding-left: $i * 1px !important;
      padding-right: $i * 1px !important;
    }

    .ptb--#{$i} {
      padding-top: $i * 1px !important;
      padding-bottom: $i * 1px !important;
    }

    .ma--#{$i} { margin: $i * 1px !important; }

    .mr--#{$i} { margin-right: $i * 1px !important; }

    .mt--#{$i} { margin-top: $i * 1px !important; }

    .mb--#{$i} { margin-bottom: $i * 1px !important; }

    .ml--#{$i} { margin-left: $i * 1px !important; }

    .mlr--#{$i} {
      margin-left: $i * 1px !important;
      margin-right: $i * 1px !important;
    }

    .mtb--#{$i} {
      margin-top: $i * 1px !important;
      margin-bottom: $i * 1px !important;
    }
}

.mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.height100percent {
    height: 100%;
}

.justify-center {
    justify-content: center;
}

.flex {
    display: flex
}

.align-center {
  align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.ml-auto{
  margin-left: auto;
}
.color-white{
    color: white;
}

.font-regular {
    font-family: $primary-font
}

.font-bold {
    font-family: $primary-font-bold
}

.font-light {
    font-family: $primary-font-light
}

.font-semibold {
    font-family: $primary-font-semibold
}

.flex-x {
  display: flex;
  flex-direction: row;
  &.align-center {
      align-items: center;
  }
  &.center {
      align-items: center;
      justify-content: center;
  }
}

.flex-y {
  display: flex;
  flex-direction: column;
  &.center {
      align-items: center;
      justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.flex-end {
  justify-content: flex-end;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.text-opacity {
  color: rgba(0,0,0,0.5)
}

.d-flex{
  display:flex;
}