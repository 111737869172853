.app-simple.light {
  --app-background: #ffffff;
  --app-color: #585858;
  --app-avatar-background: #f0f3f7;
  --app-info-color: #9b9b9b;
  --app-info-border: #d7e0e9;
  --app-chat-background: #f0f3f7;
  --app-people-color: gray;
  --app-people-active-color: #00839B;
}

.app-simple.light .pn-msg-input {
  --msg-input__background: transparent;
  --msg-input__send__background: #00839B;
}

.app-simple.light .pn-msg-list--light {
  --msg-list__background: transparent;
  --msg--hover__background: #e5e8ec;
}

.app-simple.light .pn-channel-list--light {
  --channel-list__background: #ffffff;
  --channel--active__background: #f0f3f7;
  --channel--hover__background: #00839B;
  --channel__name__color: #585858;
  --channel--active__name__color: #585858;
}

.app-simple {
  background: var(--app-background);
  color: var(--app-color);
  display: flex;
  height: 88vh;
  overflow: hidden;
}

.app-simple .pn-msg-input {
  --msg-input__send__borderRadius: 24px;
  --msg-input__send__padding: 10px 20px;
  --msg-input__textarea__borderRadius: 24px;
  --msg-input__textarea__padding: 12px 24px;
}

.app-simple .pn-channel-list {
  --channel__description__display: none;
  --channel__name__fontSize: 12px;
  --channel__name__fontWeight: 400;
  --channel__padding: 12px 24px;
}

.app-simple .pn-msg-list {
  --member__padding: 9px 0;
  --member--hover__background: transparent;
}

.app-simple .channels {
  background: var(--app-background);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.app-simple .channels h4 {
  margin: 34px 24px 12px;
}

.chat {
  background: var(--app-chat-background);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  margin: 20px 20px 20px 0;
  padding-bottom: 30px;
  position: relative;
  width: 70%;
}

