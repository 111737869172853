.cardContainer{
    width: 250px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 2% !important;
    margin-top: 10px;
    border-radius: 5px;
    /* background: #D9EDF1 !important; */
}
.chartIcon{
    outline:none;
    background: none;
    border: none;
    cursor: pointer;
}
.chartIcon:hover{
    color: crimson;
}
.HeadContent{
    border-bottom: 1px solid blueviolet;
    display: flex;
    font-size: 17px;
    font-weight: 600;
    justify-content: space-between;
}
.numbers{
    color: rgb(28, 33, 121);
    font-size: 15px;
    
}
.headConatiner{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    min-width: 300px;
}
.bodyContent{
    display: flex;
    
    justify-content: space-between;
    font-size: 15px;
    padding: 5px 0px;
}

.homeContainer{
    background-color:#ffffff;
    padding: 10px;
}